<script setup>
import sprzedawaczLogo from '@/assets/images/portals/sprzedawacz.svg';
import gratkaLogo from '@/assets/images/portals/Gratka_logo.svg';
import autotraderLogo from '@/assets/images/portals/autotrader.png';
import autoplacLogo from '@/assets/images/portals/autoplac-logo.svg';

definePageMeta({
  layout: 'plain',
})

useHead({
  title: "Skalkuluj ratę leasingu VEHIS",
  meta: [
    {name: 'description', content: 'Leasing dla osób prywatnych i przedsiębiorców. Uproszczona procedura. Decyzja w 15 minut.'}
  ],
  link: [
    {name: 'robots', content: 'noindex, follow'},
  ]
});

const route = useRoute();
const leasing = useLeasingParams();
const siteConfig = useSiteConfig();
const {openCallPopup} = useCallPopup();
const breakpoints = useBreakpoints();

const decode = (text) => {
  try {
    return decodeURIComponent(atob(text).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''))
  } catch {
    return "";
  }
}

const title = ref(decode(route.query.title))
const price = ref(Math.ceil(parseInt(route.query.price) / 1.23) || 100000);
const portal = ref(route.query.portal || null);
const installment = ref(0);
const car = ref({
  price: toRaw(price.value),
  installment: 0,
  modelName: toRaw(title.value)
});

const image = ref(decode(route.query.image));
const url = ref(decode(route.query.url));

const paramsOpened = ref(true);
const isMobileView = computed(() => breakpoints.lte('sm'));

const availablePortalsLogo = {
  'sprzedawacz.pl': sprzedawaczLogo,
  'gratka.pl': gratkaLogo,
  'autotrader.pl': autotraderLogo,
  'autoplac.pl': autoplacLogo
}

if (portal.value === null || !availablePortalsLogo[portal.value]) {
  await navigateTo("/");
  throw createError({
    statusMessage: "Moved permanently",
    statusCode: 301,
  });
}

watch(() => isMobileView.value,
  (newValue) => {
    paramsOpened.value = true;
  }
)


</script>

<template>
  <AppHeaderMinimal opened>
    <template v-slot:default>
      <div class="md:py-4 bg-white flex flex-col md:flex-row md:w-full md:gap-4 md:divide-x">
        <div class="md:block md:grow ">
          <Accordion :expanded="paramsOpened">
            <div class="pb-1 mb-4 md:mb-0">
              <CarFinancing class="md:block md:grow" />
            </div>
          </Accordion>
        </div>
        <CarPrices class="md:w-[166px] xl:w-[308px] md:pl-4 md:flex-col xl:flex-row md:items-end border-t md:border-t-0 py-4 md:py-0"
                   :price="price"
                   :installment="car.installment"
        />
      </div>
    </template>
    <template v-slot:button>
      <ButtonToggle class="md:hidden text-sm font-light text-azure !fill-azure"
                    v-model:toggled="paramsOpened"
                    labelToggled="Zwiń"
                    with-chevron>
        <template v-slot:default>Dopasuj ratę</template>
        <template v-slot:toggled>Zwiń</template>
      </ButtonToggle>
    </template>
  </AppHeaderMinimal>

  <Container no-paddings class="pt-36 md:pt-60 xl:pt-40">
    <LayoutColumns>
      <LayoutMainColumn noflex>
        <div class="overflow-hidden">
          <div class="w-full">
            <div class="flex flex-col xl:flex-row gap-4 lg:gap-0">
              <div class="mr-8 flex flex-col grow w-full">
                <div class="text-2xl font-light text-gray-700">{{ title }}</div>
                <div class="text-lg flex gap-2 items-end font-extralight mb-4">z portalu
                  <div>
                    <img class="h-8" :src="availablePortalsLogo[portal]" :alt="portal" />
                  </div>
                </div>

                <div class="font-extralight text-lg space-y-2 mb-8">
                  <div class="flex gap-2 items-center">
                    <IconCheckGreen />
                    <div>
                      Leasing dla
                      <b>przedsiębiorców i osób nieprowadzących działalności gospodarczej</b>
                    </div>
                  </div>
                  <div class="flex gap-2 items-center">
                    <IconCheckGreen />
                    <div>
                      <b>Uproszczona procedura</b> bez dokumentów finansowych
                    </div>
                  </div>
                  <div class="flex gap-2 items-center">
                    <IconCheckGreen />
                    <div>
                      Decyzja
                      <b>w 15 minut</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full xl:w-[408px] xl:min-w-[408px] flex items-center">
                <div class="w-full">
                  <div class="rounded-lg aspect-[4/3] w-full bg-gray-200 bg-cover bg-center bg-no-repeat"
                       :style="{backgroundImage: `url(${image})`}">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div>
          <SubContentHeader>Jak skorzystać z oferty VEHIS?</SubContentHeader>
          <ContentSteps>
            <ContentStep selected>Wybierz samochód w portalu
              <div class="h-5 inline align-bottom">
                <img class="max-h-5" :src="availablePortalsLogo[portal]" :alt="portal" />
              </div>
            </ContentStep>
            <ContentStep>
              <b>Zostaw kontakt.</b><br />Wspólnie skalkulujemy ofertę finansowania i ubezpieczenia.
            </ContentStep>
            <ContentStep>Finansowanie przyznamy<br /><b>w 15 minut</b> od złożenia wniosku.
            </ContentStep>
            <ContentStep>
              <b>Podpisz umowę online</b><br />lub przez kuriera.
            </ContentStep>
          </ContentSteps>
        </div>

        <div class="rounded-lg bg-azure px-6 py-8 flex justify-between items-center w-full flex-col md:flex-row gap-8 mb-8">
          <div class="text-white text-center text-lg md:text-xl md:text-left">
            Chcesz dowiedzieć się więcej? Zadzwoń!
          </div>
          <div class="w-full md:w-auto">
            <ButtonRounded color="white" class="whitespace-nowrap" @click="openCallPopup">
              <div class="flex gap-2 px-4 items-center text-azure">
                <IconPhone stroke="#0A66A8" />
                <div>{{ siteConfig.contactPhone }}</div>
              </div>
            </ButtonRounded>
          </div>
        </div>

        <BlockDrivePlus />

        <div>
          <SubContentHeader>Korzyści leasingu VEHIS</SubContentHeader>
          <StaticGains />
        </div>

        <StaticSeparator />


      </LayoutMainColumn>
      <LayoutSideColumn>
        <FormContact :car="car"
                     :additionalData="{url: url}"
                     source="Portale ogłoszeniowe"
                     class="lg:!top-72 xl:!top-52"
                     docking />
      </LayoutSideColumn>
    </LayoutColumns>

    <StaticHeader>Często zadawane pytania</StaticHeader>

    <div class="lg:mx-20 mt-6 mb-10">
      <StaticFaqItem question="Jakie dokumenty będą potrzebne do złożenia wniosku o finansowanie?" opened>
        Jeżeli prowadzisz działalność gospodarczą wystarczy, że wyślesz do nas prosty wniosek, w którym m.in. podasz nam numer NIP swojej firmy, dane z dokumentu tożsamości i wyrazisz zgodę na weryfikację w bazach (może być telefonicznie). Jeżeli nie prowadzisz działalności oprócz wniosku i zgód poprosimy Cię dodatkowo o przesłanie oświadczenia o miejscu zatrudnienia i dochodzie.
      </StaticFaqItem>
      <StaticFaqItem question="Jak długo muszę prowadzić działalność, żeby dostać finansowanie w VEHIS?">
        W VEHIS finansujemy startupy już od pierwszego dnia działalności gospodarczej.
      </StaticFaqItem>
      <StaticFaqItem question="Czy mogę skorzystać z oferty VEHIS, jeżeli nie prowadzę działalności gospodarczej?">
        Nie musisz być przedsiębiorcą, żeby skorzystać z naszej oferty. Finansujemy zarówno przedsiębiorców jak i konsumentów.
      </StaticFaqItem>
      <StaticFaqItem question="Na czym polega możliwość wymiany lub zwrotu samochodu przed końcem umowy?">
        W naszej umowie leasingu wprowadziliśmy możliwość jej przedterminowego zakończenia bez podawania przyczyny. W dowolnym momencie możesz zgłosić do nas chęć rozliczenia i zakończenia umowy. Zestawimy wówczas Twoje zobowiązanie z prognozowaną wartością samochodu (wstępna wycena na bazie Eurotax). Jeżeli pozostała do spłaty kwota przewyższy wartość samochodu, to wpłacisz nam różnicę w formie kaucji i możesz oddać samochód do VEHIS. Od tego momentu nie będziemy wystawiali kolejnych faktur i wystawimy Twój samochód na sprzedaż. Ty też możesz pozyskać Klienta we własnym zakresie! Po sprzedaży nastąpi rozliczenie, w którym dodatkowo uwzględniamy zwrot z niewykorzystanej części ubezpieczenia. Jeżeli po finalnym rozliczeniu zostanie nadwyżka to w całości Ci ją wypłacimy. Jeżeli przychód ze sprzedaży i zwrot z ubezpieczenia nie pokryją wartości zobowiązania będziesz musiał dopłacić różnicę (w tym celu wykorzystamy kaucję, o której piszemy powyżej). Po zamknięciu całej transakcji możesz wziąć leasing na inny interesujący Cię samochód lub po prostu zakończyć naszą współpracę.
      </StaticFaqItem>
      <StaticFaqItem question="Czy mogę sfinansować samochód spoza oferty VEHIS?">
        Choć oferta VEHIS obejmuje wszystkie najpopularniejsze segmenty i większość marek dostępnych w Polsce to może się zdarzyć, że swój upragniony samochód znajdziesz u innego dostawcy. Oczywiście my nadal bardzo chętnie go sfinansujemy! W VEHIS finansujemy samochody osobowe i dostawcze do 3,5 t, nowe i używane do 5 lat z maksymalnym przebiegiem 150 tys. km. Samochód musi mieć dowód zakupu w postaci faktury VAT lub faktury VAT marża.
      </StaticFaqItem>
    </div>
  </Container>

  <AppFooterMinimal />
  <Blend />
</template>
